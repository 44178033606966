import { getAssetAudityearList } from '@app/api/asset.api';
import { CompanyData, getCompanyDetail } from '@app/api/org.api';
import AuditYearForm from '@app/components/AuditYear/AuditYearForm';
import { CreateOrgForm } from '@app/components/Organization/CreateOrgForm';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
//import { Card } from '@app/components/common/Card/Card';
import { Modal } from '@app/components/common/Modal/Modal';
import AssetUpload from '@app/components/dashboard/AssetUpload';
import { AuditYearTable } from '@app/components/tables/Tables/AuditYearTable';
import { OrgTable } from '@app/components/tables/Tables/OrgTable';
import { Tables } from '@app/components/tables/Tables/Tables';
import { UserTable } from '@app/components/tables/Tables/UserTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Card, Col, Row, Space } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const CompanyDetailPage: React.FC = () => {
  //const tableData =
  //const user = useState(u)
  const { id } = useParams();
  const user = useAppSelector((state) => state?.user?.user);
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isYearModalVisible, setIsYearModalVisible] = useState<boolean>(false);
  const [isUploadFormVisible, setIsUploadFormVisible] = useState<boolean>(false);

  const [isAuditYearAdded, setIsAuditYearAdded] = useState<boolean>(false);
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const [company, setCompany] = useState<CompanyData>();
  // /fetch-company/id

  const fetch = useCallback(() => {
    getCompanyDetail(id).then((res) => {
      setCompany(res);
    });
  }, [id]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsUserCreated(!val);
  };

  const updateAuditStatus = () => {
    setIsYearModalVisible(false);
    setIsAuditYearAdded(!isAuditYearAdded);
  };

  const updateUploadStatus = () => {
    setIsUploadFormVisible(false);
    //setIsAuditYearAdded(!isAuditYearAdded);
  };
  const showUploadForm = () => {
    setIsUploadFormVisible(true);
  };
  return (
    <>
      {user && (
        <>
          <Card>
            <h2> Company Info </h2>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>Company Name</b> : {company?.companyName}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Company Id </b> : {company?.companyId}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>Description</b> : {company?.companyDesc}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Created by </b> : {company?.createdBy}
                </p>
              </Col>
            </Row>
          </Card>
          <Modal
            title={'Create Organizaton'}
            centered
            visible={isLargeModalVisible}
            onOk={() => setIsLargeModalVisible(false)}
            onCancel={() => setIsLargeModalVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <CreateOrgForm callbackfunction={updateStatus} reRender={isUserCreated} />
            </Row>
          </Modal>
          <Modal
            title={'Add Audit year'}
            centered
            visible={isYearModalVisible}
            onOk={() => setIsYearModalVisible(false)}
            onCancel={() => setIsYearModalVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <AuditYearForm callbackfunction={updateAuditStatus} reRender={isAuditYearAdded} companyId={id} />
            </Row>
          </Modal>
          <Modal
            title={'Upload Asset List'}
            centered
            visible={isUploadFormVisible}
            onOk={() => setIsUploadFormVisible(false)}
            onCancel={() => setIsUploadFormVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <AssetUpload callbackfunction={updateUploadStatus} />
            </Row>
          </Modal>
          {user?.userType === 'ADMIN' && (
            <Row align="middle" justify="end" style={{ margin: '0.5rem' }}>
              <Button type="primary" onClick={() => setIsYearModalVisible(true)}>
                Create Audit
              </Button>
            </Row>
          )}
          <AuditYearTable
            title="Audit List"
            reRender={isAuditYearAdded}
            companyId={id}
            callbackfunction={showUploadForm}
          />
          {user?.userType === 'ADMIN' && <UserTable title="Users" reRender={isUserCreated} companyId={id} />}
        </>
      )}
    </>
  );
};

export default CompanyDetailPage;
