import { getAssetAudityearList } from '@app/api/asset.api';
import { CreateOrgForm } from '@app/components/Organization/CreateOrgForm';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { Modal } from '@app/components/common/Modal/Modal';
import AssetUpload from '@app/components/dashboard/AssetUpload';
import { OrgTable } from '@app/components/tables/Tables/OrgTable';
import { Tables } from '@app/components/tables/Tables/Tables';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Col, Row } from 'antd';

import React, { useEffect, useState } from 'react';

const OrganisationPage: React.FC = () => {
  //const tableData =
  //const user = useState(u)
  const user = useAppSelector((state) => state.user.user);
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsUserCreated(!val);
  };
  return (
    <>
      {user && user?.userType === 'SUPER_ADMIN' ? (
        <>
          <Row align="middle" justify="end" gutter={[40, 40]} style={{ marginRight: '0.5rem' }}>
            <Button type="primary" onClick={() => setIsLargeModalVisible(true)}>
              Create Organization
            </Button>
          </Row>
          <Modal
            title={'Create Organizaton'}
            centered
            visible={isLargeModalVisible}
            onOk={() => setIsLargeModalVisible(false)}
            onCancel={() => setIsLargeModalVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <CreateOrgForm callbackfunction={updateStatus} reRender={isUserCreated} />
            </Row>
          </Modal>
          <OrgTable title="Organizations" reRender={isUserCreated} />
        </>
      ) : null}
    </>
  );
};

export default OrganisationPage;
