import { fetchAssetDetails, getAssetAudityearList } from '@app/api/asset.api';
import { useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Col, Image, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card.styles';
import { getOrgDetails } from '@app/api/org.api';
import { UserTable } from '@app/components/tables/Tables/UserTable';
import { OrgUserTable } from '@app/components/tables/Tables/OrgUserTable';

const OrgDetailPage: React.FC = ({}) => {
  //const tableData =
  //const user = useState(u)
  const { id } = useParams();
  const user = useAppSelector((state) => state.user.user);
  const [orgData, setOrgData] = useState<any>();
  const fetchOrg = async () => {
    const result = await getOrgDetails(id);
    setOrgData(result);
  };
  useEffect(() => {
    fetchOrg();
  }, []);
  return (
    <>
      <Card>
        <h2> Organization Info </h2>
        {orgData && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>Org Name</b> : {orgData?.orgName}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Org Code </b> : {orgData?.orgId}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>Org description</b> : {orgData?.orgDescription}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>Created on</b> : {orgData?.createdOn}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Created by</b> : {orgData?.createdBy}
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
      <OrgUserTable title="Users" orgId={id} />
    </>
  );
};

export default OrgDetailPage;
