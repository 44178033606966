import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './CreateCompanyForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { doCreateCompany, doCreateOrg } from '@app/store/slices/userSlice';
//import { Select } from '@app/components/common/selects/Select/Select';

interface CompanyFormData {
  companyName: string;
  companyDesc: string;
  orgId: string | undefined;
}

const initValues = {
  companyName: 'Organization name',
  companyDescription: 'Description',
  orgId: '-',
};
//const options = [{ value: 'REVIEWER' }, { value: 'AUDITOR' }];

export const CreateCompanyForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (values: CompanyFormData) => {
    setLoading(true);
    values.orgId = user?.orgId;
    dispatch(doCreateCompany(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Company Created Successfully',
          description: '',
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <S.Title>Enter Organization Details</S.Title>
        <Auth.FormItem
          name="companyName"
          label="Company name"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="companyDesc"
          label="Company description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Add Company
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
