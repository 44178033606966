import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as S from './Tables.styles';
import { Space, TablePaginationConfig, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import { Table } from 'components/common/Table/Table';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { getUserlist, sendResetPwdMail, updateUserStatus, UserData, UserPageinagtion } from '@app/api/user.api';
import { Pagination } from '@app/api/table.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { SearchOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

type DataIndex = keyof UserData;

export const UserTable: React.FC<any> = ({ ...props }) => {
  const [tableData, setTableData] = useState<{
    data: UserData[] | undefined;
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { companyId, title, reRender } = props;
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const user = useAppSelector((state) => state.user.user);
  const [searchText, setSearchText] = useState('');
  const [tableKey, setTableKey] = useState(0);
  //const [searchKey, setSearchKey] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);
  const [tableReq, setTableReq] = useState<UserPageinagtion>({
    pageNum: initialPagination.current,
    pageSize: initialPagination.pageSize,
  });
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      const tableReqeust: UserPageinagtion = {
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      };

      if (companyId != undefined && companyId != '') {
        tableReqeust.companyId = companyId;
      }
      if (searchText != undefined && searchText != '' && searchedColumn != undefined && searchedColumn != '') {
        tableReqeust[searchedColumn] = searchText;
        //tableReq.firstName = searchText;
      }

      console.log('12qw');
      console.log(tableReqeust);
      console.log(companyId);
      setTableReq(tableReqeust);

      //getBasicTableData(pagination)
      getUserlist(tableReqeust).then((res) => {
        //if (isMounted.current) {
        setTableData({
          data: res.userList,
          pagination: { current: res.pageNo, pageSize: res.pageSize, total: res.totalElements },
          loading: false,
        });
        //}
      });
    },
    [companyId, searchText, searchedColumn],
    // isMounted],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      console.log('fetch-111111');
      //setTableData((tableData) => ({ ...tableData, loading: true }));
      fetch(pagination);
    },
    [fetch],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch, reRender]);

  const changeUserStatus = (record: any, updated_status: string) => {
    updateUserStatus({ userId: record.userId, userStatus: updated_status }).then((res) => {
      console.log(tableData.pagination);
      handleTableChange(tableData.pagination);
      notificationController.success({
        message: 'User status changed successfully',
        description: `${record.userId} status changed to ${updated_status}.`,
      });
    });
  };

  const sendResetPwd = (record: any) => {
    sendResetPwdMail(record.userId).then((res) => {
      console.log(res);
      notificationController.success({
        message: 'Successfully sent password reset mail to User',
      });
    });
    return true;
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm({ closeDropdown: false });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    confirm({ closeDropdown: false });
    setSearchText('');
    setSearchedColumn('');
    setTableKey((tableKey) => tableKey + 1);
    //tableReq.firstName = '';
    //tableReq.lastName = '';
  };
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<UserData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          {/**<Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          */}
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record &&
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())) ??
      true,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    /**  render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <span>
          {record?.firstName} {record?.lastName}
        </span>
      ),*/
  });

  const columns: ColumnsType<UserData> = [
    {
      title: 'User Id ',
      dataIndex: 'userId',
      key: 'userId',
      render: (text, record) => {
        const link = '/user/' + record.userId;
        return (
          <Button type="link" href={link}>
            {text}
          </Button>
        );
      },
      //filterMode: 'tree',
      //filterSearch: true,
      ...getColumnSearchProps('userId'),
      // onFilter: (value: string | number | boolean, record: UserData) => record.userId.includes(value.toString()),
    },
    {
      title: 'Name ',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <span>
          {record.firstName} {record.lastName}
        </span>
      ),
      showSorterTooltip: false,
      ...getColumnSearchProps('firstName'),
    },
    {
      title: 'User Type ',
      dataIndex: 'userType',
      key: 'userType',
      showSorterTooltip: false,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      showSorterTooltip: false,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      showSorterTooltip: false,
      width: '15%',
      render: (text, record) => {
        return (
          <Space>
            {user && user?.userType === 'ADMIN' && record.status !== 'PENDING_VERIFICATION' ? (
              record.status !== 'ACTIVE' ? (
                <Button
                  type="ghost"
                  onClick={() => {
                    changeUserStatus(record, 'ACTIVE');
                  }}
                >
                  Activate
                </Button>
              ) : (
                <>
                  <Button
                    type="ghost"
                    onClick={() => {
                      sendResetPwd(record);
                    }}
                  >
                    Reset Password
                  </Button>
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      changeUserStatus(record, 'BLOCKED');
                    }}
                  >
                    Block
                  </Button>
                  <Button
                    type="default"
                    danger
                    onClick={() => {
                      changeUserStatus(record, 'DISABLED');
                    }}
                  >
                    Disable
                  </Button>
                </>
              )
            ) : null}
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={title} padding="1.25rem 1.25rem 0">
          <Table
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey="userId"
            key={tableKey}
            bordered
          />
        </S.Card>
        {/**<S.Card id="tree-table" title={t('tables.treeTable')} padding="1.25rem 1.25rem 0">
          <TreeTable />
        </S.Card>
        <S.Card id="editable-table" title={t('tables.editableTable')} padding="1.25rem 1.25rem 0">
          <EditableTable />
        </S.Card>
          */}
      </S.TablesWrapper>
    </>
  );
};
