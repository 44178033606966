import { Card } from '@app/components/common/Card/Card';
import React from 'react';
import { LoginForm } from '../components/auth/LoginForm/LoginForm';
import logo from 'assets/logo.png';
import { Image, Space } from 'antd';
const LoginPage: React.FC = () => {
  return (
    <>
      <Card>
        <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
          <Image width={200} src={logo} />
        </Space>
        <LoginForm />
      </Card>
    </>
  );
};

export default LoginPage;
