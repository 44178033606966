import React, { useEffect, useState, useCallback } from 'react';
import * as S from './Tables.styles';

import { Space, TablePaginationConfig } from 'antd';

import { Table } from 'components/common/Table/Table';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';

import { getUserlist, updateUserStatus, UserData, UserPageinagtion } from '@app/api/user.api';
import { Pagination } from '@app/api/table.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { notificationController } from '@app/controllers/notificationController';
import { getAssetAudityearList, setActiveAudityear } from '@app/api/asset.api';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { setSelectedAudit } from '@app/store/slices/userSlice';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

export const AuditYearTable: React.FC<any> = ({ ...props }) => {
  const [tableData, setTableData] = useState<{
    data: any[] | undefined;
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: true,
  });
  const { t } = useTranslation();
  const user = useAppSelector((state) => state?.user?.user);
  const { isMounted } = useMounted();
  const { reRender, companyId, callbackfunction } = props;
  const dispatch = useAppDispatch();
  //const callbackfunction = props.callbackfunction;

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      const tableReq: UserPageinagtion = {
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      };

      //getBasicTableData(pagination)
      getAssetAudityearList(companyId).then((res) => {
        console.log(res);
        if (isMounted.current) {
          setTableData({
            data: res.auditList,
            pagination: { current: res?.pageNo, pageSize: res?.pageSize, total: res?.totalElements },
            loading: false,
          });
        }
      });
    },
    [isMounted],
  );
  const changeAudityear = (record: any, updated_status: string) => {
    tableData.loading = true;
    const values = {
      auditId: record.auditId,
      enable: true,
    };
    setActiveAudityear(values).then((res) => {
      handleTableChange(initialPagination);
      notificationController.success({
        message: 'Audit year changed successfully',
      });
    });
  };

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch, reRender]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetch(pagination);
  };

  const showUploadForm = (record: any) => {
    callbackfunction();
    dispatch(setSelectedAudit(record.auditId));
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Audit Id',
      dataIndex: 'auditId',
      render: (text, record) => {
        const link = '/company/' + record.companyId + '/audit/' + record.auditId;
        return (
          <Button type="link" href={link}>
            {text}
          </Button>
        );
      },
    },
    {
      title: 'Audit Name',
      dataIndex: 'auditYear',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: 'Created by ',
      dataIndex: 'createdBy',
      showSorterTooltip: false,
    },
    {
      title: 'status',
      dataIndex: 'active',
      showSorterTooltip: false,
      render: (text: boolean) => <span>{text ? 'Active' : 'Inactive'}</span>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      showSorterTooltip: false,
      width: '15%',
      render: (text, record) => {
        return (
          <>
            {!record.active && user && user.userType == 'ADMIN' ? (
              <Button
                type="ghost"
                onClick={() => {
                  changeAudityear(record, 'ACTIVE');
                }}
              >
                Mark as Active
              </Button>
            ) : null}
            {!record.catalogPresent ? (
              user && user.userType == 'ADMIN' ? (
                <Button
                  type="ghost"
                  onClick={() => {
                    showUploadForm(record);
                  }}
                >
                  Upload Catalogue
                </Button>
              ) : (
                <span>Catalog not uploaded</span>
              )
            ) : (
              <span>Catalog uploaded</span>
            )}
          </>
        );
      },
    },
  ];

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={props.title} padding="1.25rem 1.25rem 0">
          <Table
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey="auditYear"
            bordered
          />
        </S.Card>
        {/**<S.Card id="tree-table" title={t('tables.treeTable')} padding="1.25rem 1.25rem 0">
          <TreeTable />
        </S.Card>
        <S.Card id="editable-table" title={t('tables.editableTable')} padding="1.25rem 1.25rem 0">
          <EditableTable />
        </S.Card>
          */}
      </S.TablesWrapper>
    </>
  );
};
