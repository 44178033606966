import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Navigate } from 'react-router-dom';
import { doLogout, refresh_token } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { setUser } from '@app/store/slices/userSlice';
import { deleteToken, deleteUser, persistToken, persistUser } from '@app/services/localStorage.service';
import { refreshToken } from '@app/api/auth.api';
//import { refreshToken } from '@app/api/auth.api';

const RequireAuth: React.FC = ({ children }) => {
  const [token, setToken] = useState(useAppSelector((state) => state.auth.token));
  console.log('2111');
  console.log(token);
  const dispatch = useAppDispatch();
  /**if (token) {
    dispatch(refresh_token('')).catch((err) => {
      console.log('11w11');
      // dispatch(doLogout());
      // notificationController.error({ message: err });
    });
  }
  const verified_token = localStorage.getItem('verify-token');
  console.log(verified_token);
  */
  const refresh = useCallback(() => {
    return refreshToken()
      .then((res) => {
        dispatch(setUser(res));
        persistToken(res.token);
        localStorage.setItem('verify-token', 'true');
        persistUser(res);
        return res.token;
      })
      .catch(() => {
        console.log('112121');
        deleteToken();
        deleteUser();
        dispatch(setUser(null));
        localStorage.setItem('verify-token', 'false');
        return '';
      });
    // setToken(result);
  }, []);
  useEffect(() => {
    if (token) {
      refresh();
    }
  }, [refresh, token]);

  return token ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

export default RequireAuth;
