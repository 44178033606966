import React, { useEffect, useState, useCallback, useRef } from 'react';
import * as S from './Tables.styles';

import { Space, TablePaginationConfig, Row, Input } from 'antd';

import { Table } from 'components/common/Table/Table';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { Modal } from '@app/components/common/Modal/Modal';
import { Pagination } from '@app/api/table.api';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { getOrgList, Pagination as OrgaisationPagination, OrginsationData } from '@app/api/org.api';
import { setSelectedOrg } from '@app/store/slices/userSlice';
import { CreateOrgAdminForm } from '@app/components/UserForm/CreateOrgAdminForm';
import { SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { SearchColumn } from '@app/components/header/Header/Header.styles';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};
type DataIndex = keyof OrginsationData;
export const OrgTable: React.FC<any> = ({ ...props }) => {
  const [tableData, setTableData] = useState<{
    data: OrginsationData[] | undefined;
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [tableKey, setTableKey] = useState(0);
  //const [searchKey, setSearchKey] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);
  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsUserCreated(!val);
  };
  const reRender = props.reRender;
  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));
      const tableReq: OrgaisationPagination = {
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
      };
      if (searchText != undefined && searchText != '' && searchedColumn != undefined && searchedColumn != '') {
        tableReq[searchedColumn] = searchText;
        //tableReq.firstName = searchText;
      }
      console.log('aaaa');
      console.log(searchText);
      console.log(SearchColumn);
      console.log(tableReq);
      //getBasicTableData(pagination)
      getOrgList(tableReq).then((res) => {
        //if (isMounted.current) {
        setTableData({
          data: res.organizationList,
          pagination: { current: res.pageNo, pageSize: res.pageSize, total: res.totalElements },
          loading: false,
        });
        //}
      });
    },
    [searchText, searchedColumn],
  );

  useEffect(() => {
    fetch(initialPagination);
  }, [fetch, reRender]);

  /** useEffect(() => {
    handleTableChange(initialPagination);
  }, [reRender]);
  */

  const handleTableChange = (pagination: TablePaginationConfig) => {
    fetch(pagination);
  };

  const createAdmin = (orgId: string) => {
    dispatch(setSelectedOrg(orgId));
    setIsLargeModalVisible(true);
    //setOrganizationId(orgId);
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm({ closeDropdown: false });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    confirm({ closeDropdown: false });
    setSearchText('');
    setSearchedColumn('');
    setTableKey((tableKey) => tableKey + 1);
    //tableReq.firstName = '';
    //tableReq.lastName = '';
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<OrginsationData> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          {/**<Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          */}
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record &&
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())) ??
      true,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    /**  render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <span>
          {record?.firstName} {record?.lastName}
        </span>
      ),*/
  });

  const columns: ColumnsType<OrginsationData> = [
    {
      title: 'Org Id ',
      dataIndex: 'orgId',
      render: (text, record) => {
        const link = '/org/' + record.orgId;
        return (
          <Button type="link" href={link}>
            {text}
          </Button>
        );
      },
      ...getColumnSearchProps('orgId'),
    },
    {
      title: 'Name ',
      dataIndex: 'orgName',
      render: (text, record) => <span>{text}</span>,
      showSorterTooltip: false,
      ...getColumnSearchProps('orgName'),
    },
    {
      title: 'Description ',
      dataIndex: 'orgDescription',
      render: (text, record) => <span>{text}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'Admins ',
      dataIndex: 'orgAdminList',
      render: (text, record) => {
        return (
          <div style={{ flexDirection: 'row', flex: 1 }}>
            {text?.map((object: any, i: number) => {
              return <p key={i}>{object}</p>;
            })}
          </div>
        );
      },
      showSorterTooltip: false,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      showSorterTooltip: false,
      width: '15%',
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="default"
              danger
              onClick={() => {
                createAdmin(record.orgId);
              }}
            >
              Create Admin
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Modal
        title={'Create User'}
        centered
        visible={isLargeModalVisible}
        onOk={() => setIsLargeModalVisible(false)}
        onCancel={() => setIsLargeModalVisible(false)}
        size="large"
        footer={<></>}
      >
        <Row justify="center">
          <CreateOrgAdminForm callbackfunction={updateStatus} reRender={isUserCreated} />
        </Row>
      </Modal>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={props.title} padding="1.25rem 1.25rem 0">
          <Table
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey="userId"
            key={tableKey}
            bordered
          />
        </S.Card>
        {/**<S.Card id="tree-table" title={t('tables.treeTable')} padding="1.25rem 1.25rem 0">
          <TreeTable />
        </S.Card>
        <S.Card id="editable-table" title={t('tables.editableTable')} padding="1.25rem 1.25rem 0">
          <EditableTable />
        </S.Card>
          */}
      </S.TablesWrapper>
    </>
  );
};
