import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Col, Input, Row, Space, TablePaginationConfig } from 'antd';
import { AssetTableRow, BasicTableRow, getBasicTableData, Pagination, Tag } from 'api/table.api';
import { Table } from 'components/common/Table/Table';
import { ColumnType, ColumnsType } from 'antd/es/table';
import { Button } from 'components/common/buttons/Button/Button';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { assetSummary, getAssetSummary, tableData as tableReqData } from '@app/api/asset.api';
import { useNavigate } from 'react-router-dom';
import * as S from '@app/components/tables/Tables/Tables.styles';
import { SearchOutlined } from '@ant-design/icons';
import { FilterConfirmProps } from 'antd/lib/table/interface';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 10,
};

type DataIndex = keyof assetSummary;

export const BasicTable: React.FC<any> = ({ ...props }) => {
  const [tableData, setTableData] = useState<{
    data: assetSummary[] | undefined;
    pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const navigation = useNavigate();
  const auditId: string = props.auditId;
  const title = props.title;
  const [searchText, setSearchText] = useState('');
  const [tableKey, setTableKey] = useState(0);
  //const [searchKey, setSearchKey] = useState('');
  const [searchedColumn, setSearchedColumn] = useState<string>('');
  const searchInput = useRef<any>(null);

  const fetch = useCallback(
    (pagination: Pagination) => {
      setTableData((tableData) => ({ ...tableData, loading: true }));

      const tableReq: tableReqData = {
        pageNum: pagination.current,
        pageSize: pagination.pageSize,
        auditId: [auditId],
      };
      if (searchText != undefined && searchText != '' && searchedColumn != undefined && searchedColumn != '') {
        tableReq[searchedColumn] = searchText;
        //tableReq.firstName = searchText;
      }
      console.log('searchText');
      console.log(searchText);
      console.log('searchColumn');
      console.log(searchedColumn);

      //getBasicTableData(pagination)
      getAssetSummary(tableReq).then((res) => {
        setTableData({
          data: res.auditedAssetsStatusSummaryList,
          pagination: {
            current: res.pageNo,
            pageSize: res.pageSize,
            total: res.totalElements,
            auditId: auditId,
          },
          loading: false,
        });
      });
    },
    [searchText],
  );

  const handleTableChange = useCallback(
    (pagination: TablePaginationConfig) => {
      console.log('fetch-111111');
      //setTableData((tableData) => ({ ...tableData, loading: true }));
      if (pagination?.current != undefined && pagination?.current > 1) fetch(pagination);
    },
    [fetch],
  );

  const navigateTo = (auditId: string, assetCode: string) => {
    const route = '/assets/' + encodeURIComponent(auditId) + '/' + encodeURIComponent(assetCode);
    navigation(route);
  };
  useEffect(() => {
    console.log('calling herrrrr');
    fetch(initialPagination);
  }, [fetch]);

  /***  useEffect(() => s{
    initialPagination.auditId = props.auditId; // props.auditYear;
    fetch(initialPagination);
  }, [auditId]);
  */

  /////

  /**useEffect(() => {
    handleTableChange(initialPagination);
  }, [isUserCreated]);
  */

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => {
    confirm({ closeDropdown: false });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void) => {
    clearFilters();
    confirm({ closeDropdown: false });
    setSearchText('');
    setSearchedColumn('');
    setTableKey((tableKey) => tableKey + 1);
    //tableReq.firstName = '';
    //tableReq.lastName = '';
  };
  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<AssetTableRow> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          {/**<Button
          type="primary"
          onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        */}
          <Button onClick={() => clearFilters && handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex);
            }}
            icon={<SearchOutlined />}
            style={{ width: 90 }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      (record &&
        record[dataIndex]
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())) ??
      true,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    /**  render: (text, record) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      <span>
        {record?.firstName} {record?.lastName}
      </span>
    ),*/
  });

  /////

  const columns: ColumnsType<AssetTableRow> = [
    {
      title: t('common.name'),
      dataIndex: 'assetName',
      render: (text: string) => <span>{text}</span>,
      ...getColumnSearchProps('assetName'),
    },
    {
      title: 'Asset code',
      dataIndex: 'assetCode',
      render: (text: string) => <span>{text}</span>,
      ...getColumnSearchProps('assetCode'),
    },
    {
      title: 'Audit name',
      dataIndex: 'auditYear',
      showSorterTooltip: false,
    },
    {
      title: 'Unverified',
      dataIndex: 'unverified',
      sorter: (a: AssetTableRow, b: AssetTableRow) => a.unverified - b.unverified,
      showSorterTooltip: false,
    },
    {
      title: 'Present',
      dataIndex: 'present',
      sorter: (a: AssetTableRow, b: AssetTableRow) => a.present - b.present,
      showSorterTooltip: false,
    },
    {
      title: 'Missing',
      dataIndex: 'missing',
      sorter: (a: AssetTableRow, b: AssetTableRow) => a.missing - b.missing,
      showSorterTooltip: false,
    },
    {
      title: 'Damaged',
      dataIndex: 'damaged',
      sorter: (a: AssetTableRow, b: AssetTableRow) => a.damaged - b.damaged,
      showSorterTooltip: false,
    },
    {
      title: 'Not Used',
      dataIndex: 'notUsed',
      sorter: (a: AssetTableRow, b: AssetTableRow) => a.notUsed - b.notUsed,
      showSorterTooltip: false,
    },
    {
      title: 'Actions',
      dataIndex: 'details',
      render: (text: string, record) => {
        return (
          <Space>
            <Button
              type="default"
              onClick={() => {
                navigateTo(auditId, record.assetCode);
              }}
            >
              View Details
            </Button>
          </Space>
        );
      },
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value: string | number | boolean, record: AssetTableRow) =>
        record.assetName.includes(value.toString()),
    },
  ];

  return (
    <>
      <S.TablesWrapper>
        <S.Card id="basic-table" title={title} padding="1.25rem 1.25rem 0">
          <Table
            columns={columns}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey="assetCode"
            key={tableKey}
            bordered
          />
        </S.Card>
      </S.TablesWrapper>
    </>
  );
};
