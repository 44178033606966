import { httpApi } from '@app/api/http.api';

export interface UserPageinagtion extends Record<string, any> {
  pageNum?: number;
  pageSize?: number;
  companyId?: string;
  orgIdList?: string[];
  userType?: string;
  orgId?: string;
  firstName?: string;
  status?: string;
  userId?: string;
}
export interface UserList {
  pageNo: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  userList: UserData[];
}

export interface UserData {
  userId: string;
  activeAuditYear?: string;
  userType: string;
  firstName: string;
  lastName: string;
  orgId?: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  voidedOn: string;
  voidedBy: string;
  status: string;
  token: string;
}

export interface UserStatusPayload {
  userId: string;
  userStatus: string;
}
export interface AssignCompanyFormData {
  userId: string;
  assignCompanyList?: string[];
  removeCompanyList?: string[];
}

export const getUserlist = (userListpayload: UserPageinagtion): Promise<UserList> => {
  return httpApi.post<UserList>('/api/user/fetch-users', { ...userListpayload }).then(({ data }) => data);
};

export const updateUserStatus = (userStatusPayload: UserStatusPayload): Promise<UserList> => {
  return httpApi.post<UserList>('/api/user/user-status', { ...userStatusPayload }).then(({ data }) => data);
};

export const sendResetPwdMail = (userid: string): Promise<any> => {
  const url = '/api/user/reset-credentials/user/' + userid;
  return httpApi.get<UserList>(url).then(({ data }) => data);
};

export const getUserCompanyMappings = (userId: string | undefined): Promise<any> => {
  return httpApi.get<any>('/api/user/user-company-mapping/user/' + userId).then(({ data }) => data);
};

export const modifyUserMappings = (values: AssignCompanyFormData): Promise<any> => {
  return httpApi.post<any>('/api/user/map-company', { ...values }).then(({ data }) => data);
};
