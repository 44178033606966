import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './SignUpForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
//import { Select } from '@app/components/common/selects/Select/Select';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  userId: string;
  userType: string;
  orgId?: string;
}

const initValues = {
  userType: 'ADMIN',
};
//const options = [{ value: 'REVIEWER' }, { value: 'AUDITOR' }];
const options = ['REVIEWER', 'AUDITOR'];
const { Option } = Select;

export const CreateOrgAdminForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);
  const organizationId = useAppSelector((state) => state.user.selectedOrg);
  const companyId = useAppSelector((state) => state.user.selectedCompany);
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    console.log('1111');
    console.log(organizationId);
    if (user && user.userType == 'SUPER_ADMIN' && organizationId) {
      values.orgId = organizationId;
      values.userType = 'ADMIN';
    }
    console.log(values);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Successfully created admin',
          description: 'You have successfully created the admin',
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>Enter Admin Details</S.Title>
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="userId"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('profile.nav.personalInfo.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        {!(user && user.userType == 'SUPER_ADMIN' && organizationId != null) && (
          <Auth.FormItem
            name="userType"
            label="User Type "
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput name="userType" disabled />
          </Auth.FormItem>
        )}

        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Create Admin
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
