import { httpApi } from '@app/api/http.api';

export interface Pagination extends Record<string, any> {
  pageNum?: number;
  pageSize?: number;
  companyName?: string;
  companyId?: string;
  orgId?: string;
  orgName?: string;
}
export interface OrgansaitonList {
  pageNo: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  organizationList: OrginsationData[];
}

export interface CompanyList {
  pageNo: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  companyList: CompanyData[];
}

export interface CompanyData {
  companyId: string;
  companyName: string;
  companyDesc: string;
  orgId: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
}

export interface OrginsationData {
  orgId: string;
  orgName: string;
  orgDescription: string;
  orgAdminList?: string[];
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
}

export interface OrgCreatePayload {
  orgName: string;
  orgDescription: string;
}

export interface CompanyCreatePayload {
  orgId: string;
  companyName: string;
  companyDesc: string;
}

export const getOrgList = (orgListPayload: Pagination): Promise<OrgansaitonList> => {
  return httpApi.post<OrgansaitonList>('/api/org/organization-list', { ...orgListPayload }).then(({ data }) => data);
};

export const getCompanyList = (orgListPayload: Pagination): Promise<CompanyList> => {
  return httpApi.post<CompanyList>('/api/company/company-list', { ...orgListPayload }).then(({ data }) => data);
};

export const updateOrg = (userStatusPayload: OrgCreatePayload): Promise<OrgansaitonList> => {
  return httpApi.post<OrgansaitonList>('/api/user/user-status', { ...userStatusPayload }).then(({ data }) => data);
};

export const createOrg = (orgCreatePayload: OrgCreatePayload): Promise<any> => {
  return httpApi.post<any>('/api/org/add-organization', { ...orgCreatePayload }).then(({ data }) => data);
};

export const updateCompany = (userStatusPayload: OrgCreatePayload): Promise<OrgansaitonList> => {
  return httpApi.post<OrgansaitonList>('/api/user/user-status', { ...userStatusPayload }).then(({ data }) => data);
};
export const createCompany = (orgCreatePayload: CompanyCreatePayload): Promise<any> => {
  return httpApi.post<any>('/api/company/add-company', { ...orgCreatePayload }).then(({ data }) => data);
};

export const getCompanyDetail = (orgId: string | undefined): Promise<any> => {
  const url = '/api/company/fetch-company/' + orgId;
  return httpApi.get<CompanyData>(url).then(({ data }) => data);
};

export const getOrgDetails = (orgId: string | undefined): Promise<any> => {
  const url = '/api/org/organization/' + orgId;
  return httpApi.get<OrginsationData>(url).then(({ data }) => data);
};
