import React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, InputNumber, Radio, Rate, Row, Slider, Select, Switch, Upload } from 'antd';
import { Input } from '../common/inputs/Input/Input';
import { fileUpload } from '@app/api/fileupload.api';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
//import { Select } from '../common/selects/Select/Select';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const AuditUploadForm: React.FC<any> = ({ ...props }) => {
  const callback = props.callbackfunction;
  //const yearsList = props.auditYearList;
  const auditId = useAppSelector((state) => state.user.selectedAudit);
  const initValues = {
    auditId: auditId,
  };
  const onFinish = async (values: any) => {
    const form = document.forms.namedItem('File_Upload');
    const formData = new FormData(form ? form : undefined);
    formData.append('auditId', values.auditId);
    const result = await fileUpload(values, formData);
    if (result) {
      callback(false);
      notificationController.success({
        message: 'Successfully imported the assets',
        description: 'assets imported',
      });
    }
  };

  return (
    <Form name="File_Upload" {...formItemLayout} onFinish={onFinish} initialValues={initValues}>
      <Form.Item name="auditId" label="Audit Id">
        <Input name="auditId" disabled />
      </Form.Item>

      <Form.Item name="file" label="Upload">
        <Input type="file" name="file" />
      </Form.Item>

      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AuditUploadForm;
