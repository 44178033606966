import axios, { AxiosRequestConfig } from 'axios';
import { readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = readToken();
  const newConfig = config;
  if (newConfig && newConfig?.headers) {
    if (token) {
      newConfig.headers.authorization = `Bearer ${token}`;
      newConfig.headers.auth_key = `${token}`;
    }
    //newConfig.headers.Accept = 'application/json';
    if (newConfig.url === '/api/catalog/asset-catalog') {
    } else {
      newConfig.headers['Content-Type'] = 'application/json';
    }
  }
  return newConfig;
}

httpApi.interceptors.request.use(authRequestInterceptor);

httpApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const message = error.response?.data?.message || error?.message;
    console.log(error.response.config.url);
    if (error.response.config.url != '/api/user/refresh-token') {
      if (error.response?.data?.statusCode === 500) {
        notificationController.error({ message: 'Error Processing Request. Please try again.' });
      } else {
        notificationController.error({ message: message });
      }
    }
    return Promise.reject(error);
  },
);
/*
httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  console.log('errror');
  console.log(process.env.REACT_APP_BASE_URL);
  console.log(error);
  throw new ApiError<ApiErrorData>(error.response?.data.message || error.message, error.response?.data);
});
*/

export interface ApiErrorData {
  message: string;
}
