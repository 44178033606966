import React from 'react';
import { UserOutlined, OrderedListOutlined, CalendarOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  role_allowed?: string;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'common.dashboard',
    key: 'dashboard',
    url: '/',
    icon: <OrderedListOutlined />,
    role_allowed: 'ADMIN',
  },
  {
    title: 'Organization',
    key: 'organisations',
    url: '/organisations',
    icon: <OrderedListOutlined />,
    role_allowed: 'SUPER_ADMIN',
  },
  {
    title: 'Users',
    key: 'users',
    url: '/users',
    icon: <UserOutlined />,
    role_allowed: 'ADMIN',
  },
];
