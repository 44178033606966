import AuditYearForm from '@app/components/AuditYear/AuditYearForm';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Modal } from '@app/components/common/Modal/Modal';
import { AuditYearTable } from '@app/components/tables/Tables/AuditYearTable';
import { UserTable } from '@app/components/tables/Tables/UserTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Row } from 'antd';

import React, { useState } from 'react';

const AuditYearPage: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isAuditYearAdded, setIsAuditYearAdded] = useState<boolean>(false);
  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsAuditYearAdded(!val);
  };
  return (
    <>
      {user && user?.userType === 'ADMIN' ? (
        <Row align="middle" justify="end" gutter={[30, 30]}>
          <Button type="primary" onClick={() => setIsLargeModalVisible(true)}>
            Add new Audit Year
          </Button>
        </Row>
      ) : null}

      <Modal
        title={'Add Audit year'}
        centered
        visible={isLargeModalVisible}
        onOk={() => setIsLargeModalVisible(false)}
        onCancel={() => setIsLargeModalVisible(false)}
        size="large"
        footer={<></>}
      >
        <Row justify="center">
          <AuditYearForm callbackfunction={updateStatus} reRender={isAuditYearAdded} />
        </Row>
      </Modal>
      <AuditYearTable title="Audit Year List" reRender={isAuditYearAdded} />
    </>
  );
};

export default AuditYearPage;
