import { httpApi } from '@app/api/http.api';
//import './mocks/auth.api.mock';
import { UserModel } from '@app/domain/UserModel';

export interface AuthData {
  userId: string;
  pswd: string;
}

export interface SignUpRequest {
  firstName: string;
  lastName: string;
  userId: string;
  userType: string;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface SecurityCodePayload {
  code: string;
}

export interface NewPasswordData {
  userId: string;
  pswd: string;
  rpswd: string;
  token: string;
}

export interface LoginRequest {
  userId: string;
  pswd: string;
}

export interface LoginResponse {
  token: string;
  user: UserModel;
}

export const login = (loginPayload: LoginRequest): Promise<LoginResponse> => {
  return httpApi.post<LoginResponse>('/api/user/login', { ...loginPayload }).then(({ data }) => data);
};
export const refreshToken = (): Promise<LoginResponse> => {
  console.log('refresssh');
  return httpApi.get<LoginResponse>('/api/user/refresh-token').then(({ data }) => data);
};
export const signUp = (signUpData: SignUpRequest): Promise<undefined> =>
  httpApi.post<undefined>('/api/user/create-user', { ...signUpData }).then(({ data }) => data);

export const resetPassword = (resetPasswordPayload: ResetPasswordRequest): Promise<undefined> =>
  httpApi.post<undefined>('/api/forgotPassword', { ...resetPasswordPayload }).then(({ data }) => data);

export const verifySecurityCode = (securityCodePayload: SecurityCodePayload): Promise<undefined> =>
  httpApi.post<undefined>('/api/verifySecurityCode', { ...securityCodePayload }).then(({ data }) => data);

export const setNewPassword = (newPasswordData: NewPasswordData): Promise<undefined> =>
  httpApi.post<undefined>('/api/user/credentials', { ...newPasswordData }).then(({ data }) => data);
