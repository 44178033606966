import React, { useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './CreateOrgForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { doCreateOrg } from '@app/store/slices/userSlice';
//import { Select } from '@app/components/common/selects/Select/Select';

interface OrgFormData {
  orgName: string;
  orgDescription: string;
}

const initValues = {
  orgName: 'Organization name',
  OrgDescription: 'Description',
};
//const options = [{ value: 'REVIEWER' }, { value: 'AUDITOR' }];

export const CreateOrgForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);

  const { t } = useTranslation();

  const handleSubmit = (values: OrgFormData) => {
    setLoading(true);
    dispatch(doCreateOrg(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: 'Organization Created Successfully',
          description: '',
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <S.Title>Enter Organization Details</S.Title>
        <Auth.FormItem
          name="orgName"
          label="Organization name"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="orgDescription"
          label="Organization description"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Create Organization
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
