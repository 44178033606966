import React, { useCallback, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './SignUpForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { DebounceSelect } from '@app/components/common/selects/debounce/Select';
import { CompanyData, getCompanyList } from '@app/api/org.api';
import { Pagination } from '@app/api/table.api';
import { Pagination as OrganizationPagination } from '@app/api/org.api';
import { result } from 'lodash';
//import { Select } from '@app/components/common/selects/Select/Select';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  userId: string;
  userType: string;
  orgId?: string;
  companyIds?: any[];
  companyList?: string[];
}

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};

const initValues = {
  firstName: 'Christopher',
  lastName: 'Johnson',
  email: 'reviewer@gmail.com',
};
//const options = [{ value: 'REVIEWER' }, { value: 'AUDITOR' }];
const options = ['REVIEWER', 'AUDITOR'];
const { Option } = Select;

export const SignUpForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);
  const organizationId = useAppSelector((state) => state.user.selectedOrg);
  const company = useAppSelector((state) => state.user.selectedCompany);
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    console.log('1111');
    console.log(organizationId);
    if (user) {
      if (user.userType == 'SUPER_ADMIN' && organizationId) {
        values.orgId = organizationId;
        values.userType = 'ADMIN';
      } else {
        values.orgId = user.orgId;
      }
    }
    const compnayIds = values.companyIds
      ? values.companyIds.map((item) => {
          return item.value;
        })
      : [];
    values.companyList = compnayIds;
    console.log(values);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  // Usage of DebounceSelect
  interface UserValue {
    label: string;
    value: string;
  }

  /**async function fetchUserList(username: string): Promise<UserValue[]> {
    console.log('fetching user', username);
    getCompanyList();

    return fetch('https://randomuser.me/api/?results=5')
      .then((response) => response.json())
      .then((body) =>
        body.results.map((user: { name: { first: string; last: string }; login: { username: string } }) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })),
      );
  }
  */
  const getKeyValue = (item: CompanyData): any => {
    return {
      label: item.companyName,
      value: item.companyId,
    };
  };

  const fetch = async (search: string): Promise<any> => {
    const tableReq: OrganizationPagination = {
      pageNum: 1,
      pageSize: 10,
      companyName: search,
    };
    const result: any = await getCompanyList(tableReq).then((res) => {
      console.log(res);
      return res.companyList.map(getKeyValue);
    });
    //getBasicTableData(pagination)
    console.log(result);
    return result;
    /*** if (isMounted.current) {
          setTableData({
            data: c,
            pagination: { current: res.pageNo, pageSize: res.pageSize, total: res.totalElements },
            loading: false,
          });
        }*/
  };

  const [value, setValue] = useState<UserValue[]>([]);

  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <S.Title>Enter User Details</S.Title>
        <Auth.FormItem
          name="companyIds"
          label="Companies"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select companies"
            fetchOptions={fetch}
            onChange={(newValue) => {
              setValue(newValue as UserValue[]);
              console.log(value);
            }}
            style={{ width: '100%' }}
          />
        </Auth.FormItem>
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="userId"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('profile.nav.personalInfo.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        {!(user && user.userType == 'SUPER_ADMIN' && organizationId != null) && (
          <Auth.FormItem
            label="User Type"
            name="userType"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Select placeholder="Please select user type">
              {options?.map((type: string) => (
                <Option key={type} value={type}>
                  {type}
                </Option>
              ))}
            </Select>
          </Auth.FormItem>
        )}

        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Create User
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
