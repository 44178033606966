import { getAuditDetails, getAuditReport } from '@app/api/asset.api';
import { BasicTable } from '@app/components/tables/BasicTable/BasicTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Card, Col, Row, Space } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const AuditPage: React.FC = () => {
  //const tableData =
  //const user = useState(u)
  const { id, auditId } = useParams();
  const [auditInfo, setAuditInfo] = useState<any>();
  const user = useAppSelector((state) => state.user.user);
  const fetchAudit = () => {
    //getBasicTableData(pagination)
    getAuditDetails(auditId || '').then((res) => {
      //if (isMounted.current) {
      setAuditInfo(res);
      //}
    });
  };
  useEffect(() => {
    fetchAudit();
  }, []);

  const downloadReport = () => {
    getAuditReport(auditId || '');
  };
  return (
    <>
      <Card>
        <h2> Audit Info </h2>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p>
              <b>Audit Name</b> : {auditInfo?.auditYear}
            </p>
          </Col>
          <Col span={12}>
            <p>
              <b>audit Id </b> : {auditInfo?.auditId}
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p>
              <b>status</b> : {auditInfo?.active ? 'Active' : 'Inactive'}
            </p>
          </Col>
          <Col span={12}>
            <p>
              <b>Created by </b> : {auditInfo?.createdBy}
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}></Col>
          <Col span={12}>
            <p>
              <Link to={'/company/' + id + '/audit/' + auditId + '/unregistered-assets'}>
                View unregistered assets list
              </Link>
            </p>
          </Col>
        </Row>
      </Card>
      <Space style={{ margin: '10px', display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          type="default"
          onClick={() => {
            downloadReport();
          }}
        >
          download report
        </Button>
      </Space>
      <BasicTable title="Asset List" auditId={auditId} />
    </>
  );
};

export default AuditPage;
