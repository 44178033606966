import { fetchAssetDetails, getAssetAudityearList } from '@app/api/asset.api';
import { useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Col, Image, Row } from 'antd';

import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card.styles';
import { UserData, UserPageinagtion, getUserCompanyMappings, getUserlist } from '@app/api/user.api';
import { Pagination } from '@app/api/table.api';
import { CompanyListTable } from '@app/components/tables/Tables/CompanyListTable';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 5,
};

const UserDetailPage: React.FC = ({}) => {
  //const tableData =
  //const user = useState(u)
  const [tableData, setTableData] = useState<{
    data: any[] | undefined;
    //pagination: Pagination;
    loading: boolean;
  }>({
    data: [],
    loading: false,
  });
  const { id } = useParams();
  const user = useAppSelector((state) => state.user.user);
  const [userData, setUserData] = useState<UserData>();
  const fetchUserCompanyDetails = async () => {
    const result = await getUserCompanyMappings(id);
    //setUserData(result);
    setTableData({
      data: result.data,
      loading: false,
    });
  };

  const fetch = () => {
    //setTableData((tableData) => ({ ...tableData, loading: true }));
    const tableReqeust: UserPageinagtion = {
      pageNum: 1,
      pageSize: 1,
      userId: id,
    };
    getUserlist(tableReqeust).then((res) => {
      console.log('111111');
      console.log(res);
      setUserData(res?.userList[0]);
    });
  };
  useEffect(() => {
    fetchUserCompanyDetails();
    fetch();
  }, []);
  return (
    <>
      <Card>
        <h2> User Info </h2>
        {userData && (
          <>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b>First Name</b> : {userData?.firstName}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Last Name </b> : {userData?.lastName}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>User Status </b> : {userData?.status}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>User created by </b> : {userData?.createdBy}
                </p>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p>
                  <b> UserType </b> : {userData?.userType}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <b>Org Id </b> : {userData?.orgId}
                </p>
              </Col>
            </Row>
          </>
        )}
      </Card>
      {<CompanyListTable title="Companies" id={id} />}
    </>
  );
};

export default UserDetailPage;
