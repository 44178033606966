import { httpApi } from '@app/api/http.api';
import fileDownload from 'js-file-download';
//import './mocks/auth.api.mock';

export interface tableData extends Record<string, any> {
  pageNum?: number;
  pageSize?: number;
  auditId?: string[];
  assetCode?: string;
  assetName?: string;
}

export interface UnregisteredtableData extends Record<string, any> {
  pageNum?: number;
  pageSize?: number;
  auditId?: string;
  assetCode?: string;
  assetName?: string;
}
export interface assetSummary {
  assetCode: string;
  auditYear: string;
  assetName: string;
  total: number;
  unverified: number;
  present: number;
  missing: number;
  damaged: number;
  notUsed: number;
}

export interface unregisterAsset {
  assetCode: string;
  assetName: string;
  assetDesc: string;
  quantity: number;
  auditId: string;
  remarks: string;
}
export interface assetSummaryResponse {
  pageNo: number;
  pageSize?: number;
  totalElements?: number;
  totalPages?: number;
  auditedAssetsStatusSummaryList?: assetSummary[];
}
export interface assetDataRequest {
  auditId?: string;
  code?: string;
}
export interface CreateAuditData {
  auditYear?: string;
  companyId?: string;
}

export interface ActiveAuditYearRequest {
  auditId: string;
  enable: boolean;
}
export const getAssetSummary = (tableData: tableData): Promise<assetSummaryResponse> => {
  return httpApi
    .post<assetSummaryResponse>('/api/report/asset-audit-status-summary', { ...tableData })
    .then(({ data }) => data);
};
export const getAuditReport = (id: string): Promise<any> => {
  return httpApi
    .get<any>('/api/report/download/asset-audit-status-summary/' + id, {
      responseType: 'blob',
    })
    .then(({ data }) => fileDownload(data, id + '.xls'));
};

export const getAssetAudityearList = (companyId: string): Promise<any> => {
  return httpApi.get<any>('/api/audit/audit-list/company/' + companyId).then(({ data }) => data);
};
export const getAuditDetails = (auditId: string): Promise<any> => {
  return httpApi.get<any>('/api/audit/fetch-audit/' + auditId).then(({ data }) => data);
};

export const setActiveAudityear = (auditYearRequest: ActiveAuditYearRequest): Promise<any> => {
  return httpApi.post<any>('/api/audit/audit-enablement', { ...auditYearRequest }).then(({ data }) => data);
};

export const getActiveAudityear = (): Promise<any> => {
  return httpApi.get<any>('/asset/api/audit/active-audit-year').then(({ data }) => data);
};

export const addAudtiYear = (values: CreateAuditData): Promise<any> => {
  return httpApi.post<any>('/api/audit/create-audit', { ...values }).then(({ data }) => data);
};

export const fetchAssetDetails = async (data: assetDataRequest): Promise<any> => {
  return httpApi
    .post<any>('/api/audit/fetch-audit-data', data, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
    .then(({ data }) => data);
};

export const fetchUraAssetDetails = async (assetcode?: string): Promise<any> => {
  return httpApi.get<any>('/api/ura/unregistered-asset/' + assetcode).then(({ data }) => data);
};
export const getUnregisteredAssetList = (tableData: UnregisteredtableData): Promise<any> => {
  return httpApi.post<any>('/api/ura/unregistered-asset-list', { ...tableData }).then(({ data }) => data);
};
