import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './SignUpForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
//import { Select } from '@app/components/common/selects/Select/Select';

interface SignUpFormData {
  firstName: string;
  lastName: string;
  userId: string;
  userType: string;
  companyId: any[];
  companyList: any[];
  orgId?: string;
}

//const options = [{ value: 'REVIEWER' }, { value: 'AUDITOR' }];
const options = ['REVIEWER', 'AUDITOR'];
const { Option } = Select;

export const CreateCompanyUserForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);
  const company = useAppSelector((state) => state.user.selectedCompany);
  const user = useAppSelector((state) => state.user.user);

  console.log('ssssss');
  console.log(company);
  const initValues = {
    firstName: 'Christopher',
    lastName: 'Johnson',
    orgId: user?.orgId,
    orgName: company?.companyName,
    companyId: [company?.companyId],
    companyList: [company?.companyId],
    email: 'reviewer@gmail.com',
  };
  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);
    console.log('1111');

    console.log(values);
    dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
  };
  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <S.Title>Enter User Details</S.Title>
        <Auth.FormItem name="orgName" label="Company " rules={[{ required: true, message: t('common.requiredField') }]}>
          <Auth.FormInput name="orgName" disabled />
        </Auth.FormItem>
        <Auth.FormItem
          name="companyList"
          label="Company "
          rules={[{ required: true, message: t('common.requiredField') }]}
          hidden
        >
          <Auth.FormInput name="companyList" hidden />
        </Auth.FormItem>
        <Auth.FormItem
          name="orgId"
          label="Company "
          rules={[{ required: true, message: t('common.requiredField') }]}
          hidden
        >
          <Auth.FormInput name="orgId" hidden />
        </Auth.FormItem>
        <Auth.FormItem
          name="firstName"
          label={t('common.firstName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="lastName"
          label={t('common.lastName')}
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInput />
        </Auth.FormItem>
        <Auth.FormItem
          name="userId"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('profile.nav.personalInfo.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput />
        </Auth.FormItem>

        <Auth.FormItem
          label="User Type"
          name="userType"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Select placeholder="Please select user type">
            {options?.map((type: string) => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Auth.FormItem>

        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Create User
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
