import { fetchAssetDetails, fetchUraAssetDetails, getAssetAudityearList } from '@app/api/asset.api';
import { useParams } from 'react-router-dom';
import { Modal } from '@app/components/common/Modal/Modal';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Col, Image, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { Card } from '@app/components/common/Card/Card.styles';

const UnregisteredAssetDetail: React.FC = ({}) => {
  //const tableData =
  //const user = useState(u)
  const { id, auditYear } = useParams();
  const user = useAppSelector((state) => state.user.user);
  const [assetData, setAssetData] = useState<any>();
  const fetchAsset = async () => {
    const result = await fetchUraAssetDetails(id);
    setAssetData(result);
  };
  useEffect(() => {
    fetchAsset();
  }, []);
  return (
    <Card>
      <h2> Asset Info </h2>
      {assetData && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p>
                <b>Asset Name</b> : {assetData?.assetName}
              </p>
            </Col>
            <Col span={12}>
              <p>
                <b>Asset Code </b> : {assetData?.assetCode}
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p>
                <b>Quantity</b> : {assetData?.quantity}
              </p>
            </Col>
            <Col span={12}>
              <p>
                <b>Added by </b> : {assetData?.createdBy}
              </p>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p>
                <b> Description </b> : {assetData?.assetDesc}
              </p>
            </Col>
            <Col span={12}>
              <p>
                <b>Remarks </b> : {assetData?.remarks}
              </p>
            </Col>
          </Row>
          {assetData?.assetEvidenceList && (
            <>
              <h2>Asset Evidence </h2>
              <Row>
                {assetData?.assetEvidenceList.map((item: any) => {
                  //alert(item.bucketInfo.url);
                  return (
                    <Image width={150} key={item.bucketInfo.fileName} src={item?.bucketInfo?.url} preview={true} />
                  );
                })}
              </Row>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default UnregisteredAssetDetail;
