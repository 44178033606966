import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { readUser } from '@app/services/localStorage.service';
import { CompanyData, createCompany, createOrg } from '@app/api/org.api';

export interface UserState {
  user: UserModel | null;
  user_created: boolean;
  year_added: boolean;
  selectedOrg: string | undefined;
  selectedCompany: CompanyData | undefined;
  selectedAudit: string | undefined;
}

const initialState: UserState = {
  user: readUser(),
  user_created: false,
  year_added: false,
  selectedOrg: undefined,
  selectedCompany: undefined,
  selectedAudit: undefined,
};

export const doCreateOrg = createAsyncThunk('org/doCreateOrg', async (companyCreatePayload: any) =>
  createOrg(companyCreatePayload),
);

export const doCreateCompany = createAsyncThunk('org/doCreateOrg', async (companyCreatePayload: any) =>
  createCompany(companyCreatePayload),
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any | null>) => {
      state.user = action.payload;
    },
    setUserCreated: (state, action: PayloadAction<any | null>) => {
      state.user_created = action.payload;
    },
    setAuditYearCreated: (state, action: PayloadAction<any | null>) => {
      state.year_added = action.payload;
    },
    setSelectedOrg: (state, action: PayloadAction<any | null>) => {
      state.selectedOrg = action.payload;
    },
    setSelectedCompany: (state, action: PayloadAction<any | null>) => {
      state.selectedCompany = action.payload;
    },
    setSelectedAudit: (state, action: PayloadAction<any | null>) => {
      state.selectedAudit = action.payload;
    },
  },
});

export const { setUser, setAuditYearCreated, setUserCreated, setSelectedOrg, setSelectedCompany, setSelectedAudit } =
  userSlice.actions;

export default userSlice.reducer;
