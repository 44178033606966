import React, { useCallback, useState } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import * as S from './SignUpForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { doSignUp } from '@app/store/slices/authSlice';
import { notificationController } from '@app/controllers/notificationController';
import { DebounceSelect } from '@app/components/common/selects/debounce/Select';
import { CompanyData, getCompanyList } from '@app/api/org.api';
import { Pagination } from '@app/api/table.api';
import { Pagination as OrganizationPagination } from '@app/api/org.api';
import { result } from 'lodash';
import { modifyUserMappings } from '@app/api/user.api';
//import { Select } from '@app/components/common/selects/Select/Select';

interface AssignFormData {
  userId: string;
  orgId?: string;
  companyIds?: any[];
  assignCompanyList?: string[];
  removeCompanyList?: string[];
}

export const AssignCompanyForm: React.FC<any> = ({ ...props }) => {
  const dispatch = useAppDispatch();
  const callback = props.callbackfunction;
  const [isLoading, setLoading] = useState(false);
  const organizationId = useAppSelector((state) => state.user.selectedOrg);
  const company = useAppSelector((state) => state.user.selectedCompany);
  const user = useAppSelector((state) => state.user.user);
  const { t } = useTranslation();

  const handleSubmit = (values: AssignFormData) => {
    setLoading(true);

    const compnayIds = values.companyIds
      ? values.companyIds.map((item) => {
          return item.value;
        })
      : [];
    const formData = {
      assignCompanyList: compnayIds,
      userId: props.id,
    };

    values.userId = props.id;
    console.log(values);
    modifyUserMappings(formData).then((res) => {
      console.log(res);
      setLoading(false);
      callback(props.reRender);
    });
    /**   dispatch(doSignUp(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        // TODO call api
        setLoading(false);
        //navigate('/auth/login');
        callback(props.reRender);
      })
      .catch((err) => {
        //notificationController.error({ message: err.message });
        setLoading(false);
      });
      */
  };

  // Usage of DebounceSelect
  interface UserValue {
    label: string;
    value: string;
  }

  /**async function fetchUserList(username: string): Promise<UserValue[]> {
    console.log('fetching user', username);
    getCompanyList();

    return fetch('https://randomuser.me/api/?results=5')
      .then((response) => response.json())
      .then((body) =>
        body.results.map((user: { name: { first: string; last: string }; login: { username: string } }) => ({
          label: `${user.name.first} ${user.name.last}`,
          value: user.login.username,
        })),
      );
  }
  */
  const getKeyValue = (item: CompanyData): any => {
    return {
      label: item.companyName,
      value: item.companyId,
    };
  };

  const fetch = async (search: string): Promise<any> => {
    const tableReq: OrganizationPagination = {
      pageNum: 1,
      pageSize: 10,
      companyName: search,
    };
    const result: any = await getCompanyList(tableReq).then((res) => {
      console.log(res);
      return res.companyList.map(getKeyValue);
    });
    //getBasicTableData(pagination)
    console.log(result);
    return result;
    /*** if (isMounted.current) {
          setTableData({
            data: c,
            pagination: { current: res.pageNo, pageSize: res.pageSize, total: res.totalElements },
            loading: false,
          });
        }*/
  };

  const [value, setValue] = useState<UserValue[]>([]);

  return (
    <Auth.FormWrapper>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark="optional">
        <S.Title>Assign Companies</S.Title>
        <Auth.FormItem
          name="companyIds"
          label="Companies"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <DebounceSelect
            mode="multiple"
            value={value}
            placeholder="Select companies"
            fetchOptions={fetch}
            onChange={(newValue) => {
              setValue(newValue as UserValue[]);
              console.log(value);
            }}
            style={{ width: '100%' }}
          />
        </Auth.FormItem>

        <Form.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            Assign Companies
          </Auth.SubmitButton>
        </Form.Item>
      </Form>
    </Auth.FormWrapper>
  );
};
