import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { Modal } from '@app/components/common/Modal/Modal';
import { UserTable } from '@app/components/tables/Tables/UserTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Row } from 'antd';

import React, { useState } from 'react';

const UserPage: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsUserCreated(!val);
  };
  return (
    <>
      {user && user?.userType === 'ADMIN' ? (
        <>
          <Row align="middle" justify="end" gutter={[30, 30]}>
            <Button type="primary" onClick={() => setIsLargeModalVisible(true)}>
              Create User
            </Button>
          </Row>
          <Modal
            title={'Create User'}
            centered
            visible={isLargeModalVisible}
            onOk={() => setIsLargeModalVisible(false)}
            onCancel={() => setIsLargeModalVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <SignUpForm callbackfunction={updateStatus} reRender={isUserCreated} />
            </Row>
          </Modal>
          <UserTable title="Users" reRender={isUserCreated} />
        </>
      ) : null}
    </>
  );
};

export default UserPage;
