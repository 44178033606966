import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import UserPage from '@app/pages/UserPage';
import AuditYearPage from '@app/pages/AuditYearPage';
import AssetDetailsPage from '@app/pages/AssetDetailsPage';
import OrganisationPage from '@app/pages/OrganisationPage';
import OrgDetailPage from '@app/pages/OrgDetailPage';
import CompanyPage from '@app/pages/CompanyPage';
import CompanyDetailPage from '@app/pages/CompanyDetailPage';
import AuditPage from '@app/pages/AuditPage';
import UserDetailPage from '@app/pages/UserDetailPage';
import UnregisteredAssets from '@app/pages/UnregisteredAssets';
import UnregisteredAssetDetail from '@app/pages/UnregisgteredAssetPage';
const DashboardPage = React.lazy(() => import('@app/pages/DashboardPage'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const Dashboard = withLoading(DashboardPage);
const PersonalInfo = withLoading(PersonalInfoPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<CompanyPage />} />
          <Route path="/users" element={<UserPage />} />
          <Route path="/user/:id" element={<UserDetailPage />} />
          <Route path="/organisations" element={<OrganisationPage />} />
          <Route path="/org/:id" element={<OrgDetailPage />} />
          <Route path="/dasbhord" element={<Dashboard />} />
          <Route path="/company/:id" element={<CompanyDetailPage />} />
          <Route path="/company/:id/audit/:auditId" element={<AuditPage />} />
          <Route path="/company/:id/audit/:auditId/unregistered-assets" element={<UnregisteredAssets />} />
          <Route path="/audit-years" element={<AuditYearPage />} />
          <Route path="/assets/:auditYear/:id" element={<AssetDetailsPage />} />
          <Route path="/assets/:auditYear/ura/:id" element={<UnregisteredAssetDetail />} />
          <Route path="/profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
