import { getAssetAudityearList } from '@app/api/asset.api';
import AuditYearForm from '@app/components/AuditYear/AuditYearForm';
import { CreateCompanyForm } from '@app/components/Company/CreateCompanyForm';
import { CreateOrgForm } from '@app/components/Organization/CreateOrgForm';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { Modal } from '@app/components/common/Modal/Modal';
import { AuditYearTable } from '@app/components/tables/Tables/AuditYearTable';
import { CompanyTable } from '@app/components/tables/Tables/CompanyTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Button, Col, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CompanyPage: React.FC = () => {
  //const tableData =
  //const user = useState(u)
  const user = useAppSelector((state) => state.user.user);
  const [isLargeModalVisible, setIsLargeModalVisible] = useState<boolean>(false);
  const [isUserCreated, setIsUserCreated] = useState<boolean>(false);
  const navigation = useNavigate();
  const updateStatus = (val: boolean) => {
    setIsLargeModalVisible(false);
    setIsUserCreated(!val);
  };
  useEffect(() => {
    if (user && user.userType === 'SUPER_ADMIN') {
      navigation('/organisations');
    }
  }, [user]);
  return (
    <>
      {user && (
        <>
          {user?.userType === 'ADMIN' && (
            <Row align="middle" justify="end" gutter={[40, 40]} style={{ marginRight: '0.5rem' }}>
              <Button type="primary" onClick={() => setIsLargeModalVisible(true)}>
                Create Company
              </Button>
            </Row>
          )}
          <Modal
            title={'Create Company'}
            centered
            visible={isLargeModalVisible}
            onOk={() => setIsLargeModalVisible(false)}
            onCancel={() => setIsLargeModalVisible(false)}
            size="large"
            footer={<></>}
          >
            <Row justify="center">
              <CreateCompanyForm callbackfunction={updateStatus} reRender={isUserCreated} />
            </Row>
          </Modal>
          <CompanyTable title="Companies" reRender={isUserCreated} />
        </>
      )}
    </>
  );
};

export default CompanyPage;
