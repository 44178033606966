import { getAuditDetails } from '@app/api/asset.api';
import { BasicTable } from '@app/components/tables/BasicTable/BasicTable';
import { UnregisteredAssetTable } from '@app/components/tables/Tables/UnregistedAssetTable';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Card, Col, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const AuditPage: React.FC = () => {
  //const tableData =
  //const user = useState(u)
  const { id, auditId } = useParams();
  const [auditInfo, setAuditInfo] = useState<any>();
  const user = useAppSelector((state) => state.user.user);
  const fetchAudit = () => {
    //getBasicTableData(pagination)
    getAuditDetails(auditId || '').then((res) => {
      //if (isMounted.current) {
      setAuditInfo(res);
      //}
    });
  };
  useEffect(() => {
    fetchAudit();
  }, []);
  return (
    <>
      <Card>
        <h2> Audit Info </h2>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p>
              <b>Audit Name</b> : {auditInfo?.auditYear}
            </p>
          </Col>
          <Col span={12}>
            <p>
              <b>audit Id </b> : {auditInfo?.auditId}
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <p>
              <b>status</b> : {auditInfo?.active ? 'Active' : 'Inactive'}
            </p>
          </Col>
          <Col span={12}>
            <p>
              <b>Created by </b> : {auditInfo?.createdBy}
            </p>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}></Col>
          <Col span={12}>
            <p>
              <Link to={'/company/' + id + '/audit/' + auditId}>View registered assets list</Link>
            </p>
          </Col>
        </Row>
      </Card>
      <UnregisteredAssetTable title="Unregistered Asset List" auditId={auditId} />
    </>
  );
};

export default AuditPage;
