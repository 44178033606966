import React from 'react';
import { Button, Checkbox, Col, Form, InputNumber, Radio, Rate, Row, Slider, Select, Switch, Upload } from 'antd';
import { Input } from '../common/inputs/Input/Input';
import { notificationController } from '@app/controllers/notificationController';
import { addAudtiYear } from '@app/api/asset.api';
//import { Select } from '../common/selects/Select/Select';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const AuditUploadForm: React.FC<any> = ({ ...props }) => {
  const callback = props.callbackfunction;
  const initialValues = {
    companyId: props.companyId,
  };
  console.log(initialValues);
  const onFinish = async (values: any) => {
    console.log(values);
    const result = await addAudtiYear(values);
    if (result) {
      callback();
      notificationController.success({ message: 'Successfully added audit year' });
    }
  };

  return (
    <Form name="audit_year" {...formItemLayout} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item name="auditYear" label="Audit Year  ">
        <Input type="text" name="auditYear" style={{ margin: 10 }} />
      </Form.Item>
      <Form.Item name="companyId" label="Company " hidden>
        <Input name="companyId" hidden />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AuditUploadForm;
